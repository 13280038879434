import { doRequest, doRequestWithPolling, Methods, ResourceTypes } from 'Request';
import {
  Circuit,
  Exchange,
  ExchangeRequestStatus,
  IPriceData,
  ISecondaryPrice,
  SecondaryCircuits,
} from 'Quotes/types/store';
import {
  CircuitData,
  DIACircuitData,
  isDIA,
  isP2NNI,
  isP2P,
  P2NNICircuitData,
  P2PCircuitData,
  SecondaryCircuitStatus,
} from 'shared/components/molecules/SecondaryCircuits/SecondaryCircuits';
import { ProductType } from 'Quotes/types/productTypes';
import { useDispatch, useSelector } from 'react-redux';
import { annualCost, selectProductType, selectQuoteId, selectSelectedPrice } from 'Quotes/selectors';
import { useCallback, useState } from 'react';
import { filterIncludedBy } from 'Request/utils/filterIncludedBy';
import { PopResource } from 'Location/NNI/types';
import { mapPrimaryPricesFromPayload, mapSecondaryPricesFromPayload } from 'shared/sagas';
import {
  addPricesToCircuit,
  selectPriceOnCircuit,
  setSecondaryCircuits,
  setSecondaryCircuitsEnabled,
} from 'Quotes/actions';
import { IQuotePricedOrOrdered } from 'Quotes/types/quoteRecord';
import { extractValue, parseAEndId, parseBEndId, toCircuit } from 'Quotes/QuoteBuilder/utils/price';
import { useSecondaryCircuitsSupported } from 'shared/components/molecules/SecondaryCircuits/data/isSupported';
import { Dispatch } from 'redux';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { Feature } from 'FeatureFlags/types';
import { useP2NNICircuitData } from 'shared/components/molecules/SecondaryCircuits/data/useP2NNICircuitData';
import { useP2PCircuitData } from 'shared/components/molecules/SecondaryCircuits/data/useP2PCircuitData';
import { useDIACircuitData } from 'shared/components/molecules/SecondaryCircuits/data/useDIACircuitData';

export interface ExchangeData {
  exchanges: Exchange[];
  selectedExchangeId: string | undefined;
  selected: Exchange | undefined;
  selectExchange: (exchange: Exchange) => void;
}

export const getSelectedCircuitFromPrice = (selectedPrice: IPriceData): Circuit | undefined => {
  if (!selectedPrice.secondary_circuits?.enabled) return undefined;

  return selectedPrice.secondary_circuits?.circuits.find(
    (it) => it.id === selectedPrice.secondary_circuits?.selectedCircuitId
  );
};
export const getSelectedSecondaryPriceFromPrice = (selectedPrice: IPriceData): ISecondaryPrice | undefined => {
  const selectedCircuit = getSelectedCircuitFromPrice(selectedPrice);
  return selectedCircuit?.prices?.find((it: ISecondaryPrice) => it.id === selectedCircuit?.selectedPriceId);
};

export const getSecondarySelectedPriceAnnualCost = (secondaryPrice: ISecondaryPrice | undefined) => {
  return secondaryPrice ? annualCost(secondaryPrice) : { A: 0, B: 0 };
};

export const useSecondaryCircuitsWithSelectedPrice = () => {
  const selectedPrice = useSelector(selectSelectedPrice);
  return useSecondaryCircuits(selectedPrice);
};

export const useSupportedSecondaryCircuits = (price: IPriceData, isFeatureEnabled: boolean) => {
  return isFeatureEnabled ? useSecondaryCircuitsSupported(price) : false;
};

export const useEnabledSecondaryCircuits = (price: IPriceData, isEnabled: boolean) => {
  return isEnabled
    ? useSecondaryCircuitsEnabled(price)
    : {
        isSupported: () => false,
        enabled: false,
        circuitData: undefined,
        selectedCircuit: undefined,
        exchangeStatus: SecondaryCircuitStatus.SHOW_CHECK_NOW,
      };
};

export const useSecondaryCircuits = (price: IPriceData) => {
  const isFeatureEnabled = featureFlag.isEnabled(Feature.SecondaryCircuits);
  const supportsSecondaryCircuits = useSupportedSecondaryCircuits(price, isFeatureEnabled);
  const secondaryCircuits = useEnabledSecondaryCircuits(price, isFeatureEnabled);
  return { ...secondaryCircuits, isSupported: () => supportsSecondaryCircuits };
};

export const useExchanges = (
  exchanges: Exchange[] | undefined,
  selectedExchangeId: string | undefined,
  onSelect: (exchange: Exchange) => Promise<void>
): ExchangeData => {
  const [userSelectedExchange, setUserSelectedExchange] = useState<Exchange>();
  const selectedExchangeIdWithDefault = selectedExchangeId ?? userSelectedExchange?.popId;
  const selectedExchange = exchanges?.find((it) => it.popId === selectedExchangeIdWithDefault);
  return {
    exchanges: exchanges ?? [],
    selectedExchangeId: selectedExchangeIdWithDefault,
    selected: selectedExchange,
    selectExchange: async (exchange: Exchange) => {
      setUserSelectedExchange(exchange);
      await onSelect(exchange);
    },
  };
};

async function doDeselectCircuit(
  selectedCircuit: Circuit | undefined,
  price: IPriceData,
  secondaryCircuits: SecondaryCircuits | undefined,
  dispatch: Dispatch
) {
  if (!selectedCircuit) return;
  await deselectCircuit(price.id);
  const updatedCircuit: SecondaryCircuits = {
    ...secondaryCircuits!,
    selectedCircuitId: undefined,
  };
  dispatch(setSecondaryCircuits(price.id, updatedCircuit));
}

let alreadyLoading = false;
async function doSelectCircuit(
  secondaryCircuits: SecondaryCircuits | undefined,
  circuitId: string,
  price: IPriceData,
  dispatch: Dispatch
) {
  const circuitAlreadyExists = !!secondaryCircuits?.circuits.find((it) => it.id === circuitId);
  if (alreadyLoading) return;

  alreadyLoading = true;
  const newCircuit = await selectCircuit(price.id, circuitId);

  const circuits =
    circuitAlreadyExists || !newCircuit
      ? secondaryCircuits!.circuits
      : [...(secondaryCircuits?.circuits ?? []), newCircuit];

  const updatedCircuit: SecondaryCircuits = {
    ...secondaryCircuits!,
    selectedCircuitId: circuitId,
    selectedAEndId: circuitId ? extractValue(parseAEndId(circuitId)) : undefined,
    selectedBEndId: circuitId ? extractValue(parseBEndId(circuitId)) : undefined,
    circuits: circuits,
  };
  dispatch(setSecondaryCircuits(price.id, updatedCircuit));
  alreadyLoading = false;
}

export const hasAEnd = (secondaryCircuits: SecondaryCircuits) => {
  if (secondaryCircuits?.aEndExchanges.length === 0) {
    secondaryCircuits.enabled = false;
  }
  return secondaryCircuits;
};
export const hasBEnd = (secondaryCircuits: SecondaryCircuits) => {
  if (secondaryCircuits?.bEndExchanges.length === 0) {
    secondaryCircuits.enabled = false;
  }
  return secondaryCircuits;
};

function processLoadedSecondaryCircuits(secondaryCircuits: SecondaryCircuits, circuitData: CircuitData | undefined) {
  const processors = circuitData?.processors ?? [];
  processors.forEach((process) => process(secondaryCircuits));
}

function getDefaultCircuitId(circuitData: CircuitData | undefined, secondaryCircuits: SecondaryCircuits | undefined) {
  if (!circuitData) return undefined;
  else if (isP2P(circuitData)) {
    return circuitData.getCircuitId(secondaryCircuits?.aEndExchanges[0], secondaryCircuits?.bEndExchanges[0]);
  } else if (isP2NNI(circuitData)) {
    return undefined; // no default possible
  } else if (isDIA(circuitData)) {
    return circuitData.getCircuitId(secondaryCircuits?.aEndExchanges[0]);
  }
  return undefined;
}

async function selectDefaultCircuit(
  secondaryCircuits: SecondaryCircuits | undefined,
  selectedCircuit: Circuit | undefined,
  price: IPriceData,
  dispatch: Dispatch,
  circuitData: CircuitData | undefined
) {
  if (selectedCircuit) return;
  const circuitId = getDefaultCircuitId(circuitData, secondaryCircuits);

  if (!circuitId) return;
  await doSelectCircuit(secondaryCircuits, circuitId, price, dispatch);
}

export type HandleSelectedCircuit = (circuitId: string | undefined) => Promise<void>;

function useCircuitData(
  secondaryCircuits: SecondaryCircuits | undefined,
  handleSelectedCircuit: HandleSelectedCircuit,
  status: ExchangeRequestStatus,
  productType: ProductType | null | undefined
) {
  if (!productType) return undefined;
  const circuitDataFactory: Record<ProductType, (() => CircuitData) | undefined> = {
    [ProductType.P2P]: (): P2PCircuitData => useP2PCircuitData(secondaryCircuits, handleSelectedCircuit, status),
    [ProductType.P2NNI]: (): P2NNICircuitData => useP2NNICircuitData(secondaryCircuits, handleSelectedCircuit, status),
    [ProductType.DIA]: (): DIACircuitData => useDIACircuitData(secondaryCircuits, handleSelectedCircuit, status),
    [ProductType.OpticalP2P]: undefined,
    [ProductType.P2CCT]: undefined,
    [ProductType.NNI2CCT]: undefined,
  };

  return circuitDataFactory[productType]?.();
}

export function useSecondaryCircuitsEnabled(price: IPriceData) {
  const [isLoading, setIsLoading] = useState(false);

  const quoteId = useSelector(selectQuoteId);
  const productType = useSelector(selectProductType);
  const secondaryCircuits = price.secondary_circuits;

  const status = secondaryCircuits?.status ?? ExchangeRequestStatus.NOT_REQUESTED;

  const selectedCircuit = secondaryCircuits?.circuits.find((it) => it?.id === secondaryCircuits?.selectedCircuitId);

  const dispatch = useDispatch();
  const handleEnabledChange = (enabled: boolean) => {
    dispatch(setSecondaryCircuitsEnabled(price.id, enabled));
  };
  const handleLoadedPrices = async (circuitId: string, prices: IPriceData[]) => {
    dispatch(addPricesToCircuit(price.id, circuitId, prices));

    if (!prices[0]) return;
    await doSelectPrice(circuitId, prices[0].id);
  };
  const handleSelectedPrice = (circuitId: string, priceId: string) => {
    dispatch(selectPriceOnCircuit(price.id, circuitId, priceId));
  };

  const handleSelectedCircuit = useCallback(
    async (circuitId: string | undefined): Promise<void> => {
      if (!circuitId) {
        return await doDeselectCircuit(selectedCircuit, price, secondaryCircuits, dispatch);
      }
      await doSelectCircuit(secondaryCircuits, circuitId, price, dispatch);
    },
    [dispatch, price, secondaryCircuits, selectedCircuit]
  );

  const handleLoadedExchanges = async (secondaryCircuits: SecondaryCircuits | undefined) => {
    if (secondaryCircuits) {
      processLoadedSecondaryCircuits(secondaryCircuits, circuitData);
    }
    dispatch(setSecondaryCircuits(price.id, secondaryCircuits));
    await selectDefaultCircuit(secondaryCircuits, selectedCircuit, price, dispatch, circuitData);
  };

  const doSelectPrice = (circuitId: string, priceId: string) => {
    handleSelectedPrice(circuitId, priceId);
    return selectPrice(price.id, circuitId, priceId);
  };

  const setEnabled = async (enabled: boolean) => {
    handleEnabledChange(enabled);
    await persistEnabledState(price.id, enabled);
  };
  const circuitData = useCircuitData(secondaryCircuits, handleSelectedCircuit, status, productType);

  const exchangeStatus = circuitData?.status() ?? SecondaryCircuitStatus.SHOW_CHECK_NOW;

  return {
    enabled: secondaryCircuits?.enabled ?? true,
    setEnabled: setEnabled,
    isLoading: isLoading || (circuitData?.isLoading ?? false),
    loadSecondaryCircuits: () => loadExchanges(price.id, handleLoadedExchanges, setIsLoading),
    exchangeStatus: exchangeStatus,
    circuits: secondaryCircuits?.circuits,
    circuitData,
    selectedCircuit: selectedCircuit,
    getStatus: () => getPriceStatus(secondaryCircuits?.selectedCircuitId, selectedCircuit) ?? exchangeStatus,
    loadPrices: async () => {
      if (!quoteId || !circuitData?.canLoadPrices()) return;
      return await loadPrices(quoteId, price.id, circuitData?.getSelectedCircuitId(), handleLoadedPrices, setIsLoading);
    },
    selectPrice: doSelectPrice,
  };
}

function getPersistEnableStateRequestConfig(enabled: boolean) {
  const enableRO2 = (priceId: string) => ({
    method: Methods.PUT,
    path: `/prices/${priceId}/enable_ro2`,
  });
  const disableRO2 = (priceId: string) => ({
    method: Methods.DELETE,
    path: `/prices/${priceId}/enable_ro2`,
  });
  return enabled ? enableRO2 : disableRO2;
}

const persistEnabledState = async (priceId: string, enabled: boolean) => {
  const buildRequestConfig = getPersistEnableStateRequestConfig(enabled);
  await doRequest(buildRequestConfig(priceId));
};

export const loadExchanges = async (
  priceId: string,
  handleLoadedExchanges: (secondaryCircuits: SecondaryCircuits | undefined) => Promise<void>,
  setIsLoading: (isLoading: boolean) => void
) => {
  setIsLoading(true);
  const response = await doRequest({
    method: Methods.GET,
    path: `/prices/${priceId}/exchanges`,
  });
  const secondaryPoPs = filterIncludedBy<PopResource>(response.included, 'pop');
  const mainPrice = mapPrimaryPricesFromPayload([response.data], secondaryPoPs)[0];
  await handleLoadedExchanges(mainPrice.secondary_circuits);
  setIsLoading(false);
};

const loadPrices = async (
  quoteId: string,
  priceId: string,
  circuitId: string | undefined,
  handleLoadedPrices: (exchangeId: string, prices: IPriceData[]) => void,
  setIsLoading: (isLoading: boolean) => void
) => {
  if (!circuitId) return;
  setIsLoading(true);
  const response = await doRequestWithPolling<{ data: IQuotePricedOrOrdered[] }>({
    method: Methods.GET,
    path: `/quotes/${quoteId}/prices/${priceId}/secondary-circuit-prices/${circuitId}`,
    onError: () => {
      setIsLoading(false);
    },
  });
  // TODO: handle errors
  const prices = response.data.filter((it) => it.type === ResourceTypes.price);
  handleLoadedPrices(circuitId, mapSecondaryPricesFromPayload(prices));
  setIsLoading(false);
};

const selectCircuit = async (priceId: string, circuitId: string) => {
  if (!circuitId) return;
  const response = await doRequest<{ data: IQuotePricedOrOrdered }>({
    method: Methods.PUT,
    path: `/prices/${priceId}/select-circuit/${circuitId}`,
  });
  const newCircuit = response.data.attributes.secondary_circuits?.circuits?.[circuitId];
  return newCircuit ? toCircuit(circuitId, newCircuit, undefined) : undefined;
};

const deselectCircuit = async (priceId: string) => {
  await doRequest<{ data: IQuotePricedOrOrdered }>({
    method: Methods.DELETE,
    path: `/prices/${priceId}/select-circuit/`,
  });
};

const selectPrice = async (priceId: string, circuitId: string, secondaryPriceId: string) => {
  await doRequest({
    method: Methods.PUT,
    path: `/prices/${priceId}/circuit/${circuitId}/select_price/${secondaryPriceId}`,
  });
};

export const getExchangeStatus = (
  exchangeStatus: ExchangeRequestStatus,
  primaryExchanges: Exchange[] | undefined,
  optionalSecondaryExchanges?: Exchange[] | undefined
): SecondaryCircuitStatus => {
  const hasExchanges = primaryExchanges && primaryExchanges.length > 0;
  const hasOptionalAEndExchanges = optionalSecondaryExchanges ? optionalSecondaryExchanges.length > 0 : true;

  if (exchangeStatus === ExchangeRequestStatus.EXCHANGES_LOADED) {
    if (!hasExchanges || !hasOptionalAEndExchanges) return SecondaryCircuitStatus.NO_EXCHANGES_AVAILABLE;
    return SecondaryCircuitStatus.SHOW_EXCHANGES;
  }

  return SecondaryCircuitStatus.SHOW_CHECK_NOW;
};

export const getPriceStatus = (
  selectedCircuitId: string | undefined,
  circuit: Circuit | undefined
): SecondaryCircuitStatus | undefined => {
  if (selectedCircuitId === undefined) return undefined;
  if (circuit?.prices === undefined) return SecondaryCircuitStatus.SHOW_LOAD_PRICES;
  if (circuit.prices?.length === 0) return SecondaryCircuitStatus.NO_PRICES_AVAILABLE;
  return SecondaryCircuitStatus.DISPLAY_PRICES;
};
